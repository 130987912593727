@use "node_modules/@tandemdiabetes/sugarmate-react-components/react-components";

.container {
  @include react-components.button;
  @include react-components.pressed;
  @include react-components.disabled;
  @include react-components.standard-focus;

  background: transparent;
  align-items: center;
  color: var(--primary);
  display: flex;
  font-size: react-components.$font-size-medium;
  font-weight: react-components.$font-weight-regular;
  margin-inline-end: -1 * react-components.$focus-border-width;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }
}

.icon {
  fill: currentcolor;
  height: 0.75rem;
  width: 0.75rem;
  margin-inline-end: 0.5rem;
}
