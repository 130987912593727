@use "node_modules/@tandemdiabetes/sugarmate-react-components/react-components";

.container {
  font-weight: react-components.$font-weight-bold;
  font-size: react-components.$font-size-medium;
  color: var(--primary);
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.625rem 0 0;
}

// no top padding when there are successive day headers with no items between
.removeTopPadding {
  padding-top: 0;
}
