@use "src/styles/modals.scss";

.trigger {
  flex-grow: 1;

  .textArea {
    cursor: pointer;
  }
}

.done {
  align-self: end;
}

.legend {
  margin-bottom: 0.5rem;
}
