@use "src/styles/forms";
@use "src/styles/public";

.container {
  @include public.container;

  padding-bottom: 1rem;
}

.submit {
  @include public.submit;

  margin-top: 1.25rem;
}
