.container {
  width: 100%;
  height: 2rem;
  display: grid;
  background: var(--data-two);
  border-radius: 0.125rem;
}

.graph {
  color: var(--accent-one);
  grid-area: 1 / 1 / 2 / 2;
  transform: scaleY(-1);
  height: 100%;
  width: 100%;
  stroke-width: 1;
  fill: none;
  stroke: currentcolor;
}
