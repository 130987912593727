@use "src/styles/graph.module.scss";

.container {
  position: absolute;
  height: 0;
  display: flex;
  align-items: center;
}

.label {
  line-height: 1;
  font-size: graph.$graph-font-size;
  text-align: left;
  color: var(--primary);
}
