@use "node_modules/@tandemdiabetes/sugarmate-react-components/react-components";

.highsAndLows {
  height: 3rem;
  width: 3rem;
  display: grid;
}

.highsAndLowsCircle {
  stroke: var(--primary);
  fill: transparent;
  stroke-width: 2.4;
  grid-area: 1 / 1 / 2 / 2;
}

.highsAndLowsValues {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-area: 1 / 1 / 2 / 2;
  font-size: react-components.$font-size-medium;
  font-weight: react-components.$font-weight-light;
}

.highsAndLowsValueSeparator {
  border: 1.2px solid var(--primary);
  width: 1.75rem;
  border-radius: 1.2px;
}
