@use "src/styles/modals.scss";

// notem, this is similar to entry modal activity icon
.activityIcon {
  display: block;
  height: 3rem;
  width: auto;
  fill: currentcolor;
}

.modalBody {
  align-items: stretch;
  display: flex;
  flex-direction: column;
}

// this displays either the search button or the search results
.searchContent {
  display: flex;
  flex-direction: column;
}
