@use "src/styles/breakpoints";
@use "src/styles/home.scss";

.container {
  padding: 0 home.$column-gap 0;
  position: relative;

  &[data-last="true"] {
    padding-bottom: home.$bottom-padding;
  }
}

@media screen and (min-width: breakpoints.$lg-min) {
  .container {
    padding: 0;
    padding-inline-end: home.$column-gap;
  }
}
