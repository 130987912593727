@use "node_modules/@tandemdiabetes/sugarmate-react-components/react-components.scss";

$line-height: 1.2;
$product-line-clamp: 2;
$brand-line-clamp: 1;
$max-product-height: $product-line-clamp * $line-height * react-components.$font-size-medium;
$max-brand-height: $brand-line-clamp * $line-height * react-components.$font-size-medium;
$result-text-gap: 0.25rem;

.total {
  margin-top: 0.5rem;
  font-weight: react-components.$font-weight-regular;
  font-size: react-components.$font-size-small;
  color: var(--primary);
}

.separator {
  margin: 1.25rem 0;
  border: 1px dashed var(--secondary);
}

.resultText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $result-text-gap;
  line-height: $line-height;
  height: $max-product-height + $max-brand-height + $result-text-gap;
}

@mixin multi-line-clamp($number-of-lines) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $number-of-lines;
  overflow: hidden;
}

.resultProduct {
  @include multi-line-clamp($product-line-clamp);

  font-weight: react-components.$font-weight-bold;
  font-size: react-components.$font-size-medium;
  color: var(--primary);
}

.resultBrand {
  @include multi-line-clamp($brand-line-clamp);

  font-weight: react-components.$font-weight-regular;
  font-size: react-components.$font-size-medium;
  color: var(--primary);
}

.result,
.results {
  transition-duration: 0.5s;
  transition-property: opacity;
  opacity: 1;
}

.hiddenResult,
.hiddenResults {
  opacity: 0;
  pointer-events: none;
}

.loadingResult {
  opacity: 0.6;
  pointer-events: none;
}

.servingImageRow {
  display: flex;
  gap: 1.25rem;
}

.contentColumn {
  max-width: calc(100% - 7.5rem);
}

.image {
  object-fit: contain;
  object-position: top right; // TODO this doesn't support RTL
  width: 6.25rem;
  height: 6.25rem;
  flex-shrink: 0;
}
