@use "node_modules/@tandemdiabetes/sugarmate-react-components/react-components";
@use "src/styles/modals";

.label {
  margin-bottom: 0.5rem;
}

.formInput {
  width: 100%;
}

.modalBody {
  @include modals.modal-body;

  gap: 1.5rem;
}

.toggleRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.375rem 0;
}
