@use "node_modules/@tandemdiabetes/sugarmate-react-components/react-components";

.container {
  @include react-components.button;

  @include react-components.standard-focus {
    padding-bottom: calc(1rem - #{react-components.$focus-border-width});
  };

  background: transparent;
  display: flex;
  padding-top: calc(1rem - #{react-components.$focus-border-width});
  padding-bottom: calc(1rem - 1px);
  padding-inline: calc(1.5rem - #{react-components.$focus-border-width});
  align-items: center;
  justify-content: flex-start;
  height: 3.25rem;
  box-sizing: border-box;
  gap: 1.25rem;
  font-weight: react-components.$font-weight-regular;
  font-size: react-components.$font-size-xm;
  text-decoration: none;
  color: var(--primary);
  border-bottom: solid 1px var(--tertiary);
}

.icon {
  height: 1.25rem;
  width: 1.25rem;
  fill: currentcolor;
}
