@use "src/styles/breakpoints";
@use "src/styles/home.scss";

// non-mobile view only
.wrapper {
  align-items: flex-end;
  display: flex;
  height: home.$heading-height;
  justify-content: flex-start;
  margin: 0;
  margin-inline-end: home.$column-gap;
}

.container {
  @include home.heading-text;
}

@media screen and (max-width: breakpoints.$md-max) {
  .wrapper {
    display: none;
  }
}
