@use "node_modules/@tandemdiabetes/sugarmate-react-components/react-components";

a.nextCta {
  align-self: center;

  // the "!important" overrides the min-width of the single-line-ellipsis
  min-width: 11.25rem !important;
}

.skipCta {
  align-self: center;
  font-weight: react-components.$font-weight-semibold;
  font-size: react-components.$font-size-medium;
  color: var(--secondary);
}
