// some variables are defined in VirtualList.module.scss
// --graph-row-height
// --graph-icon-size

.row {
  height: var(--graph-row-height);
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
}

.graphItem {
  align-items: center;
  background: var(--primary);
  border-radius: 0.25rem;
  border: solid 1px var(--contrast);
  color: var(--contrast);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 100%;
  max-width: 100%;
  opacity: 1;
  padding: 0;
  padding-inline: 0.25rem;
  position: absolute;
  transform: translateX(-50%);
  transition-duration: 0.5s;
  transition-property: opacity;
  z-index: 1;
}

.message {
  overflow: hidden;
  padding-inline-start: 0.25rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: calc(var(--graph-row-height) * 0.5);
}

.compactGraphItem {
  min-width: min-content;

  .message {
    display: none;
  }
}

.activityIcon {
  fill: currentcolor;
  flex-shrink: 0;
  height: var(--graph-icon-size);
  width: var(--graph-icon-size);
}

.sourceIcon {
  fill: currentcolor;
  padding-inline-start: 0.25rem;
  height: var(--graph-icon-size);
  width: var(--graph-icon-size);
}

.timeMarker {
  height: calc(var(--graph-row-height) + 0.75rem);
  background-color: var(--primary);
  opacity: 1;
  position: absolute;
  transform: translateX(-50%);
  transition-duration: 0.5s;
  transition-property: opacity;
  width: 2px;
  z-index: -1;
}

.hiddenGraphItem,
.hiddenTimeMarker {
  opacity: 0;
}

.extraCompactGraphItem {
  .sourceIcon {
    display: none;
  }
}
