@use "src/styles/breakpoints";

.container {
  flex-grow: 1;
}

@media screen and (max-width: breakpoints.$md-max) {
  .containerHidden {
    display: none;
  }
}
