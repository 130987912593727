@use "src/styles/modals";

.label {
  margin-bottom: 0.5rem;
}

.unitsInput {
  width: 100%;
}

.modalBody {
  @include modals.modal-body;

  gap: 1.5rem;
}
