@use "src/styles/graph.module.scss";

// aligns the virtual list and the yAxis lables
.content {
  // these settings prevent the z-index settings in the graph from colliding with the rest of the page
  position: relative;
  z-index: 0;
  width: 100%;
  box-sizing: border-box;
  height: graph.$total-height;
  display: flex;
}
