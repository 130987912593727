@use "src/styles/modals";

$item-height: 4.6125rem;
$max-items: 5;
$gap: 0.5rem;

.headingIcon {
  display: block;
  fill: currentcolor;
  height: 3rem;
  margin-bottom: 1.25rem;
  width: auto;
}

.entryTemplatesList {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: $gap;
}

.item {
  flex-shrink: 0;
}

.itemButton {
  color: var(--primary);
}
