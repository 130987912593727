@use "src/styles/breakpoints";
@use "src/styles/home.scss";

.container {
  align-items: flex-end;
  display: flex;
  height: home.$heading-height;
  justify-content: space-between;
  margin: 0;
  margin-inline-start: home.$column-gap;
}

@media screen and (max-width: breakpoints.$md-max) {
  .container {
    display: none;
  }
}

.title {
  @include home.heading-text;
}
