@use "node_modules/@tandemdiabetes/sugarmate-react-components/react-components.scss";

// styles for modals

$gap: 1.25rem;

@mixin modal-body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: $gap;
}

.modalBody {
  @include modal-body;
}

// use this to style submit buttons that look like modal buttons
.modalMultiButton {
  @include react-components.modal-multi-button;
}
