.container {
  height: 100%;
  direction: ltr;
}

%section {
  display: flex;
  align-items: stretch;
}

.top {
  @extend %section;

  &.falling {
    justify-content: flex-end;
    flex-direction: column;
  }

  &.rising {
    justify-content: flex-start;
    flex-direction: column-reverse;
  }

  // TODO

  /*
  .graph-activity-row {
    margin-top: var(--graph-row-height);
  }
  */
}

.bottom {
  @extend %section;

  &.falling {
    justify-content: flex-start;
    flex-direction: column;
  }

  &.rising {
    justify-content: flex-end;
    flex-direction: column-reverse;
  }

  // TODO

  /*
  .graph-activity-row {
    margin-bottom: var(--graph-row-height);
  }
  */
}

.glucoseSpacer {
  pointer-events: none;
}

//  uncomment these styles when debugging activity placement

/*
.graph-activities-container--top {
  background: rgba(red, 0.25);
}
.graph-activities-glucose-spacer {
  background: rgba(yellow, 0.25);
}
.graph-activities-container--bottom {
  background: rgba(blue, 0.25);
}
*/
