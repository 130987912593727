@use "src/styles/breakpoints";
@use "src/styles/home.scss";

.container {
  align-items: center;
  display: flex;
  height: home.$heading-height;
  justify-content: space-between;
  margin: 0 home.$column-gap;
  gap: 1.5rem;
}

.tabs {
  display: flex;
  gap: 1.5rem;
  align-self: flex-end;
}

@media screen and (min-width: breakpoints.$lg-min) {
  .container {
    display: none;
  }
}
