@use "node_modules/@tandemdiabetes/sugarmate-react-components/react-components";
@use "src/styles/public";
@use "src/styles/breakpoints";

.container {
  @include public.container;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: stretch;
}

@media screen and (min-width: breakpoints.$lg-min) {
  .container {
    color: inherit;
  }
}

.logoContainer {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.logoIcon {
  height: 9rem;
  width: 9rem;
}

.logoText {
  width: 13.5rem;
  height: 2.375rem;
}

.link {
  color: var(--primary);
  align-items: center;
  align-self: stretch;
  border-top: 1px solid var(--tertiary);
  display: flex;
  flex-shrink: 0;
  font-size: react-components.$font-size-xl;
  font-weight: react-components.$font-weight-regular;
  height: 5rem;
  justify-content: center;
  text-align: center;
  text-decoration: none;
}

@media screen and (min-width: breakpoints.$lg-min) {
  .container {
    justify-content: center;
    gap: 4rem;
  }

  .logoContainer {
    flex-grow: 0;
  }
}
