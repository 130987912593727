@mixin form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 1.25rem;
  gap: 1.25rem;
}

.form {
  @include form;
}

@mixin input {
  width: 100%;
}

.input {
  @include input;
}
