@use "node_modules/@tandemdiabetes/sugarmate-react-components/react-components";
@use "src/styles/home.scss";

.button {
  @include react-components.button;
  @include react-components.pressed;
  @include react-components.standard-focus;

  background: transparent;
  margin: -1 * react-components.$focus-border-width;
}

.title {
  @include home.heading-text;

  border-bottom-color: transparent;
}

.activeTitle {
  border-bottom: home.$active-tab-border-bottom;
}
