@use "sass:math";

$graph-default-icon-size: 1rem;

.container {
  height: 100%;
  flex-grow: 1;
  position: relative;
  overflow: hidden;

  @for $i from 5 through 20 {
    // 0.5 <-> 2.0
    $graph-icon-scale: math.div($i, 10);

    &[data-icon-scale="#{$graph-icon-scale}"] {
      $graph-icon-size: $graph-default-icon-size * $graph-icon-scale;
      $graph-row-height: $graph-icon-size + 0.5rem;

      // these variables are used in GraphItem.module.scss
      --graph-icon-size: #{$graph-icon-size};
      --graph-row-height: #{$graph-row-height};
    }
  }
}
