@use "node_modules/@tandemdiabetes/sugarmate-react-components/react-components";
@use "src/styles/dialogs";

.container {
  padding: 1.25rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.dataSource {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.description {
  font-size: react-components.$font-size-medium;
  font-weight: react-components.$font-weight-light;
}

.nightscoutLabel {
  margin-bottom: 0.5rem;
}

.nightscoutURLInput {
  width: 100%;
  margin-bottom: 0.625rem;
}

.nightscoutTokenInput {
  width: 100%;
}

.noNewReadingsMessage {
  font-weight: react-components.$font-weight-semibold;
}

.dexcomTroubleshootingList {
  margin: 0;
  padding: 0;
  padding-inline-start: 2rem;
}

.rangeInputContainer {
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
}

.rangeInputSlider {
  flex-grow: 1;
}

// these styles match the sign up styles
.privacyCheckboxContainer {
  border: 1px solid var(--primary);
  border-radius: 0.5rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
  background: transparent;
  transition-property: background;
  transition-duration: react-components.$button-transition-duration;

  &[data-checked="true"] {
    background: var(--container-background);
  }
}

label.checkbox {
  font-size: react-components.$font-size-medium;
  padding: 0;
}

.link {
  @include react-components.standard-focus;

  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 4px;
}

.linkIcon {
  color: var(--primary);
  fill: currentcolor;
  height: 1.25rem;
  width: 1.25rem;
}

.addCta {
  align-self: center;
}
