.nutritionDisplay {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
  width: 50%;
}

.nutritionValue {
  cursor: pointer;
}
