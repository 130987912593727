@use "node_modules/@tandemdiabetes/sugarmate-react-components/react-components";

%user-initial {
  align-items: center;
  align-self: center;
  border-radius: 50%;
  color: var(--contrast);
  display: flex;
  flex-shrink: 0;
  font-weight: react-components.$font-weight-bold;
  justify-content: center;
  background-color: var(--accent-two);
}

.userInitial {
  @extend %user-initial;
}

.userInitialButton {
  @include react-components.button;
  @include react-components.pressed;
  @include react-components.standard-focus;
  @extend %user-initial;
}
