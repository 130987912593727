@use "sass:math";
@use "src/styles/graph.module.scss";

.container {
  position: absolute;
  width: 0;
  top: 0;
  bottom: 0;
}

.line {
  height: graph.$vertical-line-height;
  left: 0; // TODO rtl
  display: flex;
  justify-content: center;
  width: 0;
  border-width: graph.$graph-line-width;
  border-left-style: solid;
  transform: translateX(math.div(graph.$graph-line-width, 2));
  border-color: var(--primary);
}

.text {
  color: var(--primary);
  font-size: graph.$graph-font-size;
  position: absolute;
  top: calc(graph.$vertical-line-height + 0.25rem);
  left: 0; // TODO rtl
  width: 0;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  white-space: nowrap;
}
