@use "node_modules/@tandemdiabetes/sugarmate-react-components/react-components";

// import fonts
@include react-components.import-montserrat;

html {
  font-size: 16px;
  height: stretch;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: var(--main-background);
  color: var(--primary);
  color-scheme: var(--color-scheme);
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overscroll-behavior: none;
  overflow-x: hidden;
  height: stretch;
}

#root {
  height: stretch;
}

* {
  font-family: react-components.$font-family-montserrat;
  hyphens: none;
}
