@use "src/styles/breakpoints";

// styles for public pages

@mixin container {
  width: 100vw;
  margin: auto;
}

.container {
  @include container;
}

@mixin submit {
  align-self: center;
  min-width: 11.25rem;
}

.submit {
  @include submit;
}

@media screen and (min-width: breakpoints.$lg-min) {
  .container {
    max-width: 36.5625rem;
  }
}
