@use "src/styles/breakpoints";
@use "src/styles/home.scss";
@use "src/styles/header";

.container {
  inset: header.$height 0 0;
  position: fixed;
  box-sizing: border-box;
  column-gap: home.$column-gap;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content minmax(0, 1fr);
  padding-top: home.$top-padding;
}

.graph {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  margin-bottom: home.$row-gap;
}

.tabs {
  grid-column: 1 / 1;
  grid-row: 2 / 2;
}

.insights {
  grid-column: 1 / 1;
  grid-row: 3 / 3;
}

.activityList {
  grid-column: 1 / 1;
  grid-row: 3 / 3;
}

@media screen and (min-width: breakpoints.$lg-min) {
  .container{
    grid-template-columns: minmax(0, 1fr) max-content;
    grid-template-rows: max-content minmax(0, 1fr);
  }

  .graph {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
  }

  .insights {
    grid-column: 1 / 1;
    grid-row: 2 / 2;
  }

  .activityList {
    grid-column: 2 / 2;
    grid-row: 1 / span 2;
    width: 23rem;
  }
}
