.reading {
  height: 0.375rem;
  width: 0.375rem;
  border-radius: 50%;
  border-width: 0;
}

.readingBased {
  &.low {
    background-color: var(--error);
  }

  &.normal {
    background-color: var(--success);
  }

  &.high {
    background-color: var(--alert);
  }
}

.monochrome {
  background-color: var(--primary);
}

.entryReading {
  background-color: var(--accent-one);
}

.clampedReading {
  opacity: 0.25;
}

.container {
  position: absolute;
  transform: translate(-50%, 50%) scale(100%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  appearance: none;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

@media (prefers-reduced-motion: no-preference) {
  .reading {
    transform: scale(100%);
    transition: transform;
    transition-duration: 0.25s;
  }

  .container {
    &:hover,
    &:focus-visible {
      .reading {
        transform: scale(150%);
      }
    }
  }
}
