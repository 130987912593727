.container {
  font-size: 0.75rem;
  padding: 0.375rem;
  border-radius: 0;
  text-align: left;
  z-index: 1;
  transition-property: transform;
  transition-duration: 0.25s;
  pointer-events: none;
  color: var(--primary);
  background-color: var(--contrast);
  border: 1px solid var(--primary);
}

.reading {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.trendIcon {
  fill: currentcolor;
}

.source {
  text-transform: capitalize;
}

.arrow {
  background-color: var(--contrast);
  border: 1px solid var(--primary);
  bottom: unset;
  height: 0.5rem;
  pointer-events: none;
  position: absolute;
  right: unset;
  transform: rotate(45deg);
  transition-duration: 0.25s;
  transition-property: top;
  width: 0.5rem;
  z-index: 1;
}
