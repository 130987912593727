@use "node_modules/@tandemdiabetes/sugarmate-react-components/react-components";

.container {
  padding: 1.25rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.inputContainer {
  flex-direction: column;
  justify-content: stretch;
}

.labelRow {
  align-items: center;
  display: flex;
  height: 2.5rem;
  margin-bottom: 0.75rem;
}

.label {
  margin-bottom: 0.75rem;
}

.emailInput {
  @extend %single-line-ellipsis;

  appearance: none;
  color: var(--primary);
  font-size: react-components.$font-size-medium;
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  width: 100%;
}

.nicknameInput {
  width: 100%;
}

.changePasswordRow {
  align-items: center;
  display: flex;
  height: 2.5rem;
  justify-content: space-between;
}

.buttonsContainer {
  display: grid;
  grid-template-columns: max-content;
  grid-template-rows: max-content max-content;
  justify-items:stretch;
  margin: auto;
  row-gap: 1.25rem;
}
