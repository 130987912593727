@use "src/styles/modals.scss";

.rangeInputContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.rangeInputSlider {
  flex-grow: 1;
}
