@use "src/styles/breakpoints";
@use "src/styles/home.scss";

.wrapper {
  align-items: flex-end;
  display: flex;
  height: home.$heading-height;
  justify-content: flex-start;
  margin: 0 home.$column-gap home.$row-gap;
}

.container {
  @include home.heading-text;

  border-bottom-color: transparent;
}

@media screen and (min-width: breakpoints.$lg-min) {
  .wrapper {
    margin: 0;
    margin-bottom: home.$row-gap;
    margin-inline-start: home.$column-gap;
  }
}
