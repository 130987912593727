@use "node_modules/@tandemdiabetes/sugarmate-react-components/react-components";

.container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-weight: react-components.$font-weight-regular;
  gap: 1.5rem;
  padding: 1.25rem 1rem;
}

.link {
  font-weight: react-components.$font-weight-semibold;
  color: var(--primary);
}
