@use "node_modules/@tandemdiabetes/sugarmate-react-components/react-components";

@mixin base-styles {
  padding-top: calc(1rem - #{react-components.$focus-border-width});
  padding-bottom: calc(1rem - #{react-components.$focus-border-width});
  border-right-width: 0;
  border-left-width: 0;
}

.feedItemContent {
  @include react-components.button;
  @include base-styles;

  @include react-components.standard-focus {
    @include base-styles;

    z-index: 1;
  };

  align-items: center;
  background: transparent;
  display: flex;
  justify-content: stretch;
  text-align: start;

  &:nth-of-type(2) {
    @include react-components.standard-focus {
      @include base-styles;

      padding-top: calc(0.5rem - #{react-components.$focus-border-width});
    }

    @include base-styles;

    padding-top: calc(0.5rem - #{react-components.$focus-border-width});
  }

  &:last-of-type {
    margin-bottom: -1px;
  }

  & + & {
    @include react-components.standard-focus {
      @include base-styles;
    }

    @include base-styles;

    padding-top: calc(1rem - 1px);
    border-top: 1px dashed var(--secondary);
    margin-top: -1px;
  }

  &:disabled {
    cursor: default;
  }
}

.feedItemContentIcon {
  fill: currentcolor;
  display: grid;
  height: 1.25rem;
  margin-inline-end: 1rem;
  place-items: center;
  width: 1.25rem;
  flex-shrink: 0;
  color: var(--primary);
}

.feedItemContentText {
  @extend %single-line-ellipsis;

  color: var(--primary);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: react-components.$font-size-medium;
  font-weight: react-components.$font-weight-regular;
  gap: 0.25rem;
}

.feedItemContentTitle {
  @extend %single-line-ellipsis;
}

.feedItemContentDescription {
  @extend %single-line-ellipsis;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feedItemContentMessage {
  @extend %single-line-ellipsis;

  color: var(--secondary);
}

.feedItemContentNoteIcon {
  fill: currentcolor;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  margin-inline-end: 0.25rem;
}

.feedItemContentSource {
  color: var(--secondary);
  font-size: react-components.$font-size-medium;
  font-style: italic;
  font-weight: react-components.$font-weight-regular;
}

.feedItemContentDataContainer {
  align-items: center;
  display: flex;
  color: var(--secondary);
}

.feedItemContentData {
  flex-shrink: 0;
  margin-inline-start: 0.75rem;
}

.feedItemContentImageIcon,
.feedItemContentLockIcon {
  fill: currentcolor;
  height: 1.25rem;
  margin-inline-start: 0.75rem;
  width: 1.25rem;
}
