@use "node_modules/@tandemdiabetes/sugarmate-react-components/react-components.scss";
@use "src/styles/modals.scss";
@use "src/styles/dialogs.scss";

.activityIcon {
  display: block;
  height: 3rem;
  width: auto;
  margin-bottom: 1.25rem;
  fill: currentcolor;
}

.headingContent {
  flex-grow: 1;
}

.row {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
}

.legend {
  margin-bottom: 0.5rem;
}

.warningParagraph {
  margin: 0;
  font-size: react-components.$font-size-small;
  font-weight: react-components.$font-weight-regular;
  line-height: 1.2;

  & + & {
    margin-top: 1em * 1.2;
  }
}

.rangeInputSlider {
  flex-grow: 1;
}
