@use "src/styles/header";
@use "src/styles/breakpoints";

.wrapper {
  inset: header.$height 0 0;
  margin: auto;
  max-width: 100vw;
  overflow: auto;
  position: fixed;
}

.container {
  margin: auto;
  width: 100%;
}

@media screen and (min-width: breakpoints.$lg-min) {
  .container {
    max-width: 36.5625rem;
  }
}
