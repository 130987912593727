@use "node_modules/@tandemdiabetes/sugarmate-react-components/react-components";

.container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-weight: react-components.$font-weight-light;
  gap: 1.25rem;
  padding: 1.25rem 1rem;
}

.disclaimerContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.hr {
  height: 1px;
  width: 100%;
  border: none;
  background-color: var(--secondary);
  margin: 0;
}

.link {
  font-weight: react-components.$font-weight-semibold;
  color: var(--primary);
}

.sugarmate {
  font-weight: react-components.$font-weight-semibold;
}

.svg {
  height: 1.25rem;
  fill: currentcolor;
}

.helpRow {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.australianSponsor {
  font-weight: react-components.$font-weight-semibold;
}
