.nutritionInfo {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
  margin: 1.25rem 0;
  flex-grow: 1;
}

.nutritionValue {
  cursor: pointer;
}
