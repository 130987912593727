@use "sass:math";
@use "src/styles/breakpoints";

$graph-line-width: 1px;
$graph-font-size: 0.75rem; // this is used for axis labels, not activity items
$top-padding: math.div($graph-font-size, 2);
$bottom-padding: $top-padding + 2.25rem;
$vertical-line-height: calc(var(--graph-data-height) + $graph-font-size);
$total-height: calc(var(--graph-data-height) + $top-padding + $bottom-padding);

:root {
  --graph-data-height: 14rem;
  --graph-top-padding: #{$top-padding};
}

@media screen and (min-width: breakpoints.$lg-min) {
  :root {
    --graph-data-height: 20rem;
  }
}
