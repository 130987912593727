@use "node_modules/@tandemdiabetes/sugarmate-react-components/react-components";

.container {
  padding: 1.25rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.activityButtons {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;
}

// TODO consider standardizing description styles
.activityButtonsDescription {
  font-size: react-components.$font-size-medium;
  font-weight: react-components.$font-weight-light;
}

.dayStatsDescription {
  margin: 0.5rem 0;
  font-size: react-components.$font-size-medium;
  font-weight: react-components.$font-weight-light;
}

// TODO convert to menu button
.activityButton {
  @include react-components.button;
  @include react-components.standard-focus;

  align-items: center;
  background-color: var(--container-background);
  border-radius: 0.5rem;
  box-shadow: react-components.$standard-shadow;
  color: var(--primary);
  display: flex;
  gap: 1rem;
  height: 3rem;
  padding: 0 1rem;
  width: 100%;

  @extend %single-line-ellipsis;
}

.activityButtonText {
  @extend %single-line-ellipsis;

  flex-grow: 1;
  font-size: react-components.$font-size-xm;
  font-weight: react-components.$font-weight-regular;
  text-align: start;
}

.handleIcon {
  height: 1.25rem;
  width: 1.25rem;
  flex-shrink: 0;
  fill: currentcolor;
}

.activityIcon {
  height: 1.25rem;
  width: 1.25rem;
  flex-shrink: 0;
  fill: currentcolor;
}

.toggleRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.375rem 0;
}
