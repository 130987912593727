@use "src/styles/forms";
@use "src/styles/dialogs";

.dialogBody {
  @include dialogs.dialog-body;

  gap: 1rem;
}

.dialogButtons {
  margin-top: 0.5rem;
}

.label {
  margin-bottom: 0.5rem;
}

@mixin input-group {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: flex-start;
}

.inputGroup {
  @include input-group;
}
