@use "node_modules/@tandemdiabetes/sugarmate-react-components/react-components.scss";
@use "src/styles/dialogs";

$context-button-size: 1.5rem;

.trigger {
  @include react-components.button;
  @include react-components.pressed;
  @include react-components.disabled;
  @include react-components.standard-focus;

  background-color: transparent;
  color: var(--primary);
  display: grid;
  height: calc((2 * #{react-components.$focus-border-width}) + #{$context-button-size});
  margin-bottom: -1 * react-components.$focus-border-width;
  margin-inline: calc(1rem - #{react-components.$focus-border-width}) (-1 * react-components.$focus-border-width);
  margin-top: -1 * react-components.$focus-border-width;
  place-items: center;
  width: calc((2 * #{react-components.$focus-border-width}) + #{$context-button-size});
}

.triggerIcon {
  fill: currentcolor;
  max-height: 100%;
  max-width: 100%;
}

button.deleteButton {
  color: var(--error);
}
