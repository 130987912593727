@use "src/styles/graph.module.scss";

.container {
  padding: graph.$top-padding 0 0;
  position: relative;
  direction: ltr;
  box-sizing: border-box;
}

.content {
  width: 100%;
  height: var(--graph-data-height);
  position: relative;
}
