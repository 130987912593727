@use "node_modules/@tandemdiabetes/sugarmate-react-components/react-components";

.container {
  padding: 1.25rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.toggleRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.375rem 0;
}

.inputContainer {
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
}

.selectContainer {
  margin-top: 0.75rem;
}

.rangeInputSlider {
  flex-grow: 1;
}

.themeButtonContainer {
  position: relative;
}

.themeButtons {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(auto-fill, minmax(7.125rem, 1fr));
  margin-top: 0.75rem;
}
