@use "node_modules/@tandemdiabetes/sugarmate-react-components/react-components";

.container {
  align-items: center;
  display: flex;
  min-height: 100vh;
  min-height: stretch;
  justify-content: center;
  max-width: 100vw;
  width: 100vw;
}

.backPrompt {
  align-items: center;
  color: var(--secondary);
  display: flex;
  font-size: react-components.$font-size-small;
  gap: 0.5rem;
  left: 1rem;
  position: fixed;
  text-decoration: none;
  top: 1rem;
}

.backIcon {
  fill: currentcolor;
  height: 1em;
  width: 1em;
}

.data {
  width: 390px;
  max-width: 100vw;
}

@media (prefers-reduced-motion) {
  .data {
    transition: transform;
    transition-duration: 1s;
    transform: scale(1);
  }
}
