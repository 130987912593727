.container {
  position: relative;
  width: max-content;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.25s;
}

.loaded {
  opacity: 1;
}

.thumbnail {
  display: block;
  width: auto;
  max-height: 10rem;
  max-width: 10rem;
}

.remove {
  position: absolute;
  top: -0.625rem;
  inset-inline-end: -0.625rem;
}
