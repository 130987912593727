@use "node_modules/@tandemdiabetes/sugarmate-react-components/react-components.scss";
@use "src/styles/modals.scss";

legend.legend {
  font-size: react-components.$font-size-medium;
  font-weight: react-components.$font-weight-regular;
  margin-bottom: 1.25rem;
  height: auto;
}

fieldset.fieldSet {
  margin-bottom: 1.25rem;
}
