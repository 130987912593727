@use "node_modules/@tandemdiabetes/sugarmate-react-components/react-components.scss";
@use "src/styles/public";
@use "src/styles/forms";

.container {
  @include public.container;

  padding-bottom: 1rem;
}

.personalInformationForm,
.accountInformationForm,
.passwordForm {
  @include forms.form;

  gap: 2.5rem;
}

.consentNoticesForm {
  @include forms.form;

  gap: 1.5rem;
}

.inputs {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1.25rem;
}

.heading {
  font-size: react-components.$font-size-large;
  font-weight: react-components.$font-weight-bold;
  text-align: center;
}

// these styles match the data source styles
label.checkbox {
  font-size: react-components.$font-size-medium;
  padding: 0;
  flex-grow: 1;
  justify-content: start;
}

.privacyCheckboxContainer {
  border: 1px solid var(--primary);
  border-radius: 0.5rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
  background: transparent;
  transition-property: background;
  transition-duration: react-components.$button-transition-duration;

  &[data-checked="true"] {
    background: var(--container-background);
  }
}

.link {
  @include react-components.standard-focus;

  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 4px;
}

.linkIcon {
  color: var(--primary);
  fill: currentcolor;
  height: 1.25rem;
  width: 1.25rem;
}

.passwordRequirements {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.passwordRequirementsHeading {
  font-size: react-components.$font-size-medium;
  font-weight: react-components.$font-weight-regular;
  margin: 0;
  padding: 0;
}

.passwordRequirementsList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: react-components.$font-size-medium;
  font-weight: react-components.$font-weight-light;
  margin: 0;
  padding-inline-start: 1.5rem;
}

.passwordRequirement::marker {
  content: "•  ";
}
