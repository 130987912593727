.searchButton {
  position: absolute;
  align-self: center;
  margin-top: 1rem;
}

.hiddenSearchButton {
  &,
  &:disabled { // TODO may have to !important here
    opacity: 0;
    pointer-events: none;
  }
}
